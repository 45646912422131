import React from "react";
import { FormattedMessage ,useIntl } from 'react-intl';


const Accord = ({index}) => {


    const intl = useIntl();
    let message = intl.formatMessage({id : `Q${index}.subtitle`})

    let parts = message.split('*');



  return (
    <>
      <div>
      {parts.map((part, idx) => (
        <p style={{margin:"0px"}} key={idx}>{part}</p>
      ))}
     
      </div>
    </>
  );
};

export default Accord;
