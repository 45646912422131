import React from 'react';
import { FormattedMessage } from 'react-intl';
const ConfirmModal = ({ show, message, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div className="exit-modal-backdrop">
      <div className="exit-modal">
        <h5>LlmWizard</h5>
        <h5 style={{padding:"0 25px"}}>{message}</h5>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
        <button className='btn-default' onClick={onConfirm}><FormattedMessage id="yes" /> </button>
        <button style={{background:"red"}} className='btn-default' onClick={onCancel}><FormattedMessage id="cancel" /> </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
