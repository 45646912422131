import { createSlice } from '@reduxjs/toolkit';
import { savePinnedChatsToLocalStorage, loadPinnedChatsFromLocalStorage , saveRenamedChatsToLocalStorage, loadRenamedChatsFromLocalStorage} from './localStorageHelpers';// İlk state değeri

// İlk state değeri
const initialState = {
  conversationText: null,
  conversationModalVisibliity: 0,
  likeStatus:null,
  dataSettings : null ,
  chatHistory : [],
  botchatHistory : [],
  messageLoading:false,
  pinnedChats: typeof window !== 'undefined' ? loadPinnedChatsFromLocalStorage() : [],
  renamedChats: typeof window !== 'undefined' ? loadRenamedChatsFromLocalStorage() : [],
};

// feedback slice'ı oluşturma 2121
export const feedbackSlice = createSlice({
  name: 'feedback', // slice'ın adı
  initialState, // ilk state değeri
  reducers: {
    // action creator'ı ve reducer'ı tanımlama
    setConversationText: (state, action) => {
      state.conversationText = action.payload;
    },
    setConversationModalVisibilty: (state, action) => {
      state.conversationModalVisibliity = action.payload;
    },
    setMessageLoading: (state, action) => {
      state.messageLoading = action.payload;
    },
    setLikeStatus: (state, action) => {
      state.likeStatus = action.payload;
    },
    setDataSettings: (state, action) => {
      state.dataSettings = action.payload;
    },
    setChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },
    setBotchatHistory: (state, action) => {
      state.botchatHistory = action.payload;
    },
    setPinnedChats: (state, action) => {
      state.pinnedChats = action.payload;
      savePinnedChatsToLocalStorage(state.pinnedChats);
    },
    addRenamedChat: (state, action) => {
      const { session_id, newName } = action.payload;
      const existingIndex = state.renamedChats.findIndex(chat => chat.session_id === session_id);
      if (existingIndex !== -1) {
        // Eğer sohbet mevcutsa, yeni adı ile güncelle
        state.renamedChats[existingIndex].newName = newName;
      } else {
        // Maksimum 25 öğe tutmak için, 25'ten fazla öğe varsa ilk öğeyi sil
        if (state.renamedChats.length >= 25) {
          state.renamedChats.shift();
        }
        state.renamedChats.push({ session_id, newName });
      }
      saveRenamedChatsToLocalStorage(state.renamedChats);
    },
  },
});

// action creator'ı diğer bileşenlerle paylaşma
export const {setMessageLoading, setConversationText,setConversationModalVisibilty,setLikeStatus, setDataSettings,setChatHistory,setBotchatHistory, setPinnedChats , addRenamedChat } = feedbackSlice.actions;

// Selector oluşturma
export const selectConversationText = state => state.feedback.conversationText;
export const selectConversationModalVisibility = state => state.feedback.conversationModalVisibliity;
export const selectLikeStatus = state => state.feedback.likeStatus;
export const dataSettings = state => state.feedback.dataSettings;
export const pinnedChats = state => state.feedback.pinnedChats;
export const chatHistory = state => state.feedback.chatHistory;
export const botchatHistory = state => state.feedback.botchatHistory;
export const renamedChats = state => state.feedback.renamedChats;
export const selectMessageLoading = state => state.feedback.messageLoading;
export const chatHistorySelector = (state) => state.feedback.chatHistory;

// Reducer export etme
export default feedbackSlice.reducer;