


const Plugins = ()=>{


    return (
        <div>
            <p>
            New features are coming!</p>
        </div>  
    )
}

export default Plugins