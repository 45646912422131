import React, { useState ,useMemo} from 'react';
import Image from "next/image";
import bolt from '../../public/images/home-icons/bolt1.png';
import glob from '../../public/images/home-icons/globe.png';
import chart from '../../public/images/home-icons/chart-network.png';
import stats from '../../public/images/home-icons/stats.png';
import first_image from '../../public/images/home-images/first_image.png';
import second_image from '../../public/images/home-images/second_image.png';
import third_image from '../../public/images/home-images/third_image.png';
import useList from '@/components/isAuth/isAuth';
import { FormattedMessage ,useIntl  } from 'react-intl';

const Design = () => {
  const intl = useIntl();
  
  const list = useList();
  const userID = list[0];
const icons = {
  bolt: bolt,
  glob: glob,
  chart: chart,
  stats: stats
};
const images = {
  first_image: first_image,
  second_image: second_image,
  third_image: third_image,
};

const data = useMemo(() => [
  { icon: "bolt", head: intl.formatMessage({ id: "design.card1.title" }), desc: intl.formatMessage({ id: "design.card1.content" }), color: "red" },
  { icon: "glob", head: intl.formatMessage({ id: "design.card2.title" }), desc: intl.formatMessage({ id: "design.card2.content" }), color: "blue" },
  { icon: "chart", head: intl.formatMessage({ id: "design.card3.title" }), desc: intl.formatMessage({ id: "design.card3.content" }), color: "green" },
  { icon: "stats", head: intl.formatMessage({ id: "design.card4.title" }), desc: intl.formatMessage({ id: "design.card4.content" }), color: "grey" },
], [intl.locale]); // intl.locale'yi dependency olarak ekliyoruz

const blogData = useMemo(() => [
  { image: "first_image", head: intl.formatMessage({ id: "design.card1.title" }), desc: intl.formatMessage({ id: "design.card1.subcontent" }) },
  { image: "second_image", head: intl.formatMessage({ id: "design.card2.title" }), desc: intl.formatMessage({ id: "design.card2.subcontent" }) },
  { image: "third_image", head: intl.formatMessage({ id: "design.card3.title" }), desc: intl.formatMessage({ id: "design.card3.subcontent" }) },
], [intl.locale]); // intl.locale'yi izlemek için burada da kullanıyoruz

  const ScrollItem = ({ icon, head, desc, color }) => {
    // Default color if not provided
    const backgroundColor = color || "#386CDC"; 

    return (
      <>
        <style jsx>{`
          .scroll-item:hover {
            height: 18em; /* Increase height on hover */
            border: 2px solid #386cdc;
            cursor: pointer;
            z-index: 1; /* Ensure hovered item is on top */
            transform: scale(1.05); /* Slightly scale up the item */
          }
  
          .try-button {
            display: none;
            position: absolute;
            bottom: 10px;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            color: #386CDC;
            font-size: 1em;
            font-weight: bold;
            font-family: var(--secondary-font);
            cursor: pointer;
          }
  
          .scroll-item:hover .try-button {
            display: block;
            opacity: 1;
            transform: translateY(0);
          }
          
          .bolt-icon-wrapper {
            background-color: ${backgroundColor};
            border-radius: 50%;
            padding: 0.8em;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3.5em; /* Default height */
            width: 3.5em;  /* Default width */
            transition: all 0.3s ease-in-out;
          }

          .scroll-item:hover .bolt-icon-wrapper {
            height: 4em; /* Increased height on hover */
            width: 4em;  /* Increased width on hover */
          }

          @media (max-width: 768px) {
            .bolt-icon-wrapper {
              height: 2.5em; /* Adjusted size for smaller screens */
              width: 2.5em;  /* Adjusted size for smaller screens */
            }
          }

          @media (max-width: 480px) {
            .bolt-icon-wrapper {
              height: 2em; /* Adjusted size for very small screens */
              width: 2em;  /* Adjusted size for very small screens */
            }
          }
        `}</style>
        <div className="scroll-item">
          <div className="bolt-icon-wrapper">
            {icon && <Image 
              src={icons[icon]} 
              alt={`${icon} Icon`} 
              className="bolt-icon"
              width={40}
              height={40}
            />}
          </div>
          <p style={{ marginTop: '1em', fontWeight: 'bold', fontFamily: 'var(--secondary-font)', marginBottom: 0 }}>{head}</p>
          <p style={{ marginTop: 5 }}>{desc}</p>
          <link>
          </link>
          <p onClick={() => {
                  userID ? window.location.href = `/chat` :  window.location.href = `/AuthPage/`;

          }} className="try-button"> <FormattedMessage id="design.try.btn" /> {'>'}</p>
        </div>
      </>
    );
  };
  
  // Define the BlogItem component
  const BlogItem = ({ image, head, desc }) => {
    const handleClick = () => {
      // Kullanıcı ID'si varsa /chat, yoksa /AuthPage yönlendirmesi
     
      userID ? (window.location.href = `/chat`) : (window.location.href = `/AuthPage/`);
    };
  
    return (
      <div className="blog-item" onClick={handleClick}>
        <div className="blog-image">
          <Image 
            src={images[image]} 
            alt={head} 
            width={350} 
            height={300} 
          />
        </div>
        <h2 className="blog-head">{head}</h2>
        <p className="blog-desc">{desc}</p>
  
        <style jsx>{`
          .blog-item {
            padding: 2em;
            margin: 1.5em;
            border-radius: 20px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;
          }
  
          .blog-item:hover {
            transform: translateY(-8px);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
          }
  
          .blog-image {
            width: 100%;
            max-width: 400px;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 1.5em;
          }
  
          .blog-head {
            font-size: 1.6em;
            font-weight: bold;
            color: #123E7B;
            margin: 0.5em 0;
            text-align: center;
            transition: color 0.3s ease;
          }
  
          .blog-item:hover .blog-head {
            color: #FFBD4C;
          }
  
          .blog-desc {
            font-size: 1em;
            color: #555;
            margin: 0;
            text-align: center;
            line-height: 1.6;
            font-family: 'var(--secondary-font)', sans-serif;
          }
        `}</style>
      </div>
    );
  };
  
  
  return (
    <>
      <style jsx>{`
        .transition-div {
          transition: all 2s ease-in-out;
          overflow: hidden;
        }
  
        .container {
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
  
        .blog-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          min-height: 20em;
          gap: 2em; /* Elemanlar arasında boşluk ekleme */
        }
  
        .signup-button {
          border: none;
          padding: 12px 20px;
          color: white;
          background-color: #386cdc;
          border-radius: 20px;
          font-size: 1em;
          font-family: var(--secondary-font);
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
        }
  
        .signup-button:hover {
          background-color: #274a91; /* Daha koyu mavi */
        }
  
        .hover-text {
          transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
  
        .hover-text:hover {
          color: #FFBD4C; /* Hover'da rengi değiştir */
          transform: scale(1.05); /* Hover'da büyüt */
        }
  
        @media (max-width: 768px) {
          .blog-container {
            flex-direction: column; /* Dikey yığın */
            align-items: center; /* Yatay merkezleme */
          }
          .container {
            margin-top: 7em;
          }
        }
      `}</style>
  
      <div className="rainbow-section-gap" style={{ marginBottom: 0 }}>
        <div className="container">
          <div className="support-container">
            <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', marginBottom: '2em' }}>
              <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <p className="hover-text" style={{ fontSize: 'clamp(22px, 1.8vw, 40px)', fontFamily: 'var(--secondary-font)', marginBottom: '0.5em' }}>
                  <FormattedMessage id="design.title" />
                </p>
                <p className="hover-text" style={{ color: '#386cdc', fontSize: 'clamp(22px, 1.8vw, 40px)', marginLeft: '.3em', fontFamily: 'var(--secondary-font)', marginBottom: '0.5em' }}>
                  <FormattedMessage id="design.content" />
                </p>
              </div>
              <div style={{ paddingRight: '4em', paddingLeft: '4em', marginTop: '1em' }}>
                <p className="hover-text" style={{ color: 'grey', fontWeight: '500', fontFamily: 'var(--secondary-font)', textAlign: 'center', margin: '0' }}>
                  <FormattedMessage id="design.card1.subcontent" />
                </p>
              </div>
            </div>
  
            <div className="blog-container">
              {blogData.map((item, index) => (
                <BlogItem 
                  key={index} 
                  image={item.image} 
                  head={item.head} 
                  desc={<span className="hover-text">{item.desc}</span>} // Add hover effect here
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
  
  
  
};

export default Design;
