import Link from "next/link";
import React from "react";
import Image from "next/image";
import image from "../../public/images/logo/evolvechat-logo.png";
import { FormattedMessage  } from 'react-intl';

const EvolveLink = () => {
  const handleClick = () => {
    window.open('https://www.evolvechat.com/', '_blank');
  };
  return (
    <>
      <style jsx>{`
        .container {
          
            
        }
      `}</style>
      <div className="rainbow-section-gap" style={{ marginBottom: 0}}>
        <div className="container" style={{ marginBottom: 0,display: 'flex', flexDirection: 'column' }}>
          <div className="container">
          <div style={{display:"flex",gap:"1em",alignItems:"center"}}>
          <h5 style={{margin:"0"}}>Powered by</h5>
          <Image src={image} width={200} height={100}></Image>
          </div>
         
                <p style={{marginTop:20}}><FormattedMessage id="evolve-link-top" /></p>
                <p><FormattedMessage id="evolve-link-bottom" /></p>
                <button
      onClick={handleClick}
      style={{
        color: '#007bff',
        textDecoration: 'underline',
        fontWeight: 'bold',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '0',
        transition: 'color 0.3s',
      }}
      onMouseEnter={(e) => e.target.style.color = '#0056b3'}
      onMouseLeave={(e) => e.target.style.color = '#007bff'}
    >
      <FormattedMessage id="evolve-link" />
    </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvolveLink;
