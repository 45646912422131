import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

const booleanSliceBot = createSlice({
  name: 'boolean',
  initialState,
  reducers: {
    toggleBooleanBot: (state) => {
      state.value = !state.value;
    },
    setBooleanTrueBot: (state) => {
      state.value = true;
    },
    setBooleanFalseBot: (state) => {
      state.value = false;
    },
  },
});

export const { toggleBooleanBot, setBooleanTrueBot, setBooleanFalseBot } = booleanSliceBot.actions;
export default booleanSliceBot.reducer;
