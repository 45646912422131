// src/serviceWorkerRegistration.js
export function register() {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
          .then(registration => {
            console.log('Service Worker başarıyla kaydedildi:', registration);
          })
          .catch(error => {
            console.log('Service Worker kaydı başarısız:', error);
          });
      });
    }
  }
  
  export function unregister() {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => {
          registration.unregister();
        })
        .catch(error => {
          console.log(error.message);
        });
    }
  }