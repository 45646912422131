import Link from "next/link";
import { useEffect,React } from "react";
import { FormattedMessage } from 'react-intl';
import "venobox/dist/venobox.min.css";
import Image from "next/image";

import ndivia from "../../public/images/menu-img/ndivia.png";


const Ndivia = () => {

  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center" >
            <div className="col-lg-12" style={{display:"flex",flex:"0 0 224px",width:"100%",flexDirection:"column",gap:"40px",paddingBlock:"72px",borderRadius:18}}>
              <div className=" text-center">
                
                <p className="home-slider-title" style={{fontSize: 'clamp(22px, 1.8vw, 40px)', fontFamily: 'var(--secondary-font)', marginBottom: 0}}>
                <FormattedMessage id="ndivia.proud" />
                </p>
              </div>
              <div style={{display:"flex",flexDirection:"row"}}>
                <div>
                    <Image src={ndivia} alt="ndivia" style={{width:"100%"}} />

                </div>
                <div>
                <FormattedMessage id="ndivia.p" />

                </div>
              </div>



            </div>
          
          </div>
        </div>
   
      </div>
     
    </>
  );
};

export default Ndivia;
