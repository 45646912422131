import Head from "next/head";

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from "react";
import { CookieContext } from "@/context/CookieContext"; // CookieContext'i import ediyoruz


const PageHead = ({ title }) => {
  const intl = useIntl();
  const router = useRouter();
  const language = intl.locale;
  //const language = router.pathname.startsWith('/tr') ? 'tr' : 'en';
  const { cookiesAccepted } = useContext(CookieContext); // CookieContext'ten çerez kabul durumunu alıyoruz

  useEffect(() => {
    if (cookiesAccepted) {
      // Çerezler kabul edilmişse Analytics'i başlat
      enableAnalytics();
    }
  }, [cookiesAccepted]); // cookiesAccepted değiştiğinde çalışacak

  const enableAnalytics = () => {
    // Google Analytics kodunu burada başlatabilirsiniz
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-4EMBSQJE87');
  };
 

  const defaultTitle = "Welcome"; // Varsayılan başlık
  const defaultDescription = "You can access all llm models in one platform."; // Varsayılan açıklama

  // title ile çevirinin mevcut olup olmadığını kontrol et
  const pageTitle = intl.messages[`${title}.title`] 
    ? intl.formatMessage({ id: `${title}.title` }) 
    : defaultTitle;

  // description ile çevirinin mevcut olup olmadığını kontrol et
  const pageDescription = intl.messages[`${title}.desc`] 
    ? intl.formatMessage({ id: `${title}.desc` }) 
    : defaultDescription;

    const canonicalUrl = intl.messages[`${title}.url`] 
    ? intl.formatMessage({ id: `${title}.url` }) 
    : null;
   
  return (
    <>
      <Head>
     
      <title>{pageTitle} || llmwizard</title>

        <meta name="description" content={pageDescription} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/llmwizard1.ico" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="apple-mobile-web-app-title" content="LLMWizard" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          <meta httpEquiv="Content-Language" content={language} />
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          {/* Eğer çerezler kabul edilmişse Google Analytics kodunu ekle */}
          {cookiesAccepted && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-4EMBSQJE87"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-4EMBSQJE87');
                `,
              }}
            />
          </>
        )}
        {/* Updated Facebook Pixel script */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '879061253781093');
              fbq('track', 'PageView');
            `,
          }}
        />


      </Head>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=879061253781093&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      </noscript>
    </>
  );
};

export default PageHead;
