import React, { useEffect } from 'react';

const Info = ({ message, show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  return (
    <div className={`info-message ${show ? 'info-message-enter' : 'info-message-exit'}`}>
      {message}
      <style jsx>{`
       .info-message {
          position: fixed;
          top: 200px;
          right: -100%;
          background-color: #3F86FF;
          color: white;
          padding: 10px 20px;
          border-radius: 5px;
          z-index: 3;
          transition: right 0.5s ease-in-out;
        }

        .info-message-enter {
          right: 20px;
        }

        .info-message-exit {
          right: -100%;
        }
      `}</style>
    </div>
  );
};

export default Info;
