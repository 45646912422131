import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import mockup from '@/public/images/menu-img/mockup.png'
import { FormattedMessage   } from 'react-intl';

const Mockup = () => {

  return (
    <>
      <div className="rainbow-pricing-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="150"
              >
               
                <h2  style={{color:"#386CDC"}}>
                <FormattedMessage id="mockup.title" /> 
                             </h2>
                <p className="description b1">
               
                <FormattedMessage id="mockup.desc" />
                </p>
              </div>
            </div>
          </div>
          <div className="row row--15">
          <Image  style={{width:"100%"}} src={mockup} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mockup;
