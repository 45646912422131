import React from 'react';
import myImage1 from '../../public/images/logo/ProdoxIcon.png';
import myImage2 from '../../public/images/generator-img/2.png';
import myImage3 from '../../public/images/generator-img/3.png';
import myImage4 from '../../public/images/generator-img/4.png';
import myImage5 from '../../public/images/generator-img/5.png';
import myImage6 from '../../public/images/generator-img/6.png';
import Image from "next/image";

const Item = ({ item }) => {
  // Resimleri bir diziye atayarak daha dinamik bir yaklaşım benimseyebiliriz.
  const images = [myImage1, myImage2, myImage3, myImage4, myImage5, myImage6];
  // item.id değerini kullanarak images dizisinden ilgili resmi alabiliriz.
  const image = images[item.img - 1]; // img 1'den başladığı için index için 1 çıkarıyoruz.

  return (
    <div className='customItem-item'>
      <Image src={myImage1} alt='logo' className='customItem-logo'/>
      <div className='customItem-texts'>
        <p className='customItem-title'>{item.template_name}</p>
        <p className='customItem-text'>{item.description}</p>
      </div>
      <button onClick={try_it} className='customItem-try-it-btn'>Try it out</button>
    </div>
  );
};

export default Item;
