import React, { useState, useEffect,useRef } from 'react';
import MessageItem from '../items/messagesItem/MessageItem';
import { useDispatch, useSelector } from "react-redux";
import { dataSettings } from "@/slices/feedbackSlice";
import { FormattedMessage, useIntl } from 'react-intl';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact tema CSS
import 'primereact/resources/primereact.min.css';         // PrimeReact temel CSS
import 'primeicons/primeicons.css';  
import { clearMessages } from "@/slices/messagesReducer";
import ConfirmModal from './ConfirmModal';
import { BallTriangle,TailSpin } from 'react-loader-spinner'; // Importing a spinner component
import { useRouter } from 'next/router';
import Plugins from './Plugins.js'
import useList from '@/components/isAuth/isAuth';
import { useAppContext } from "@/context/Context";

const CodeGenerator = () => {
  const router = useRouter();
  const { url } = router.query;
  const toast = useRef(null);
  const context = useAppContext() || {}; // useAppContext undefined dönerse boş obje kullanılır.
  const { shouldCollapseLeftbar = false } = context;
  const [getSettings, setGetSettings] = useState();
  const [selectedButton, setSelectedButton] = useState(0);
  const [name, setName] = useState("Hello");
  const [greetingType, setGreetingType] = useState("");
  const [message, setMessage] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [buttonNames,setButtonNames] = useState(['Setup', 'Instructions']);
  const [selectedModel, setSelectedModel] = useState('1');
  const [value, setValue] = useState("");
  const [placeHolder, setPlaceHolder] = useState("Type a message...");
  const [directive, setDirective] = useState("");
  const [model, setModel] = useState([]);
  const list = useList();
  const intl = useIntl();
  const [templateid, setTemplateid] = useState("");
  const [usertemplateid, setUserTemplateid] = useState("");
  const [userquestion, setUserQuestion] = useState("");
  const [display_disc, setDisplaydisc] = useState();
  const [response, setResponse] = useState("");
  const sessionId = "";
  const [imageUrl, setImageUrl] = useState('/images/bot-icons/custom.png');
  const [imageName, setImageName] =useState("");
  const [role, setRole] = useState('');
  const [objective, setObjective] = useState('');
  const [style, setStyle] = useState('');
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [isCustomWizard, setIsCustomWizard] = useState(false);
const [loading, setLoading] = useState(false);
const [responseLoading,setResponseLoading] = useState(false);
const API_URL = process.env.NEXT_PUBLIC_API_URL || 'https://llmwizard.com';
const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
const userID = list[0];
const dispatch = useDispatch();




const [showModal, setShowModal] = useState(false);
const [pendingRoute, setPendingRoute] = useState(null);
const [allowNavigation, setAllowNavigation] = useState(false); // Bu bayrak yönlendirmeye izin verecek



useEffect(() => {
  const handleRouteChange = (url) => {
    if (allowNavigation) {
      setAllowNavigation(false); // Bir kez yönlendirme yapılınca bu bayrağı sıfırlıyoruz
      return; // Kullanıcı onayladıysa normal yönlendirme yapılır
    }

    // Kullanıcı onay vermemişse, sayfa değişikliği durdurulmalı
    setPendingRoute(url);
    setShowModal(true); // Modal'ı göster
    router.events.emit('routeChangeError'); // Yönlendirme işlemini durdur
    throw "Route change aborted"; // Next.js'de yönlendirmeyi durdurmak için gerekli
  };

  router.events.on('routeChangeStart', handleRouteChange);

  return () => {
    router.events.off('routeChangeStart', handleRouteChange);
  };
}, [router, allowNavigation]);

const handleConfirm = () => {
  setShowModal(false);
  setAllowNavigation(true); // Yönlendirme için izin veriyoruz
  if (pendingRoute) {
    router.push(pendingRoute); // Onaylandığında yönlendirme yapılır
  }
};

const handleCancel = () => {
  setShowModal(false); // İptal edildiğinde modal kapanır
  setPendingRoute(null); // Bekleyen yönlendirme sıfırlanır
};


/* useEffect(() => {
  const handleBeforeUnload = (e) => {
    const message = intl.formatMessage({
      id: 'exitWarning',
      defaultMessage: 'Sayfadan çıkmak istediğinize emin misiniz?',
    });
    e.preventDefault();
    e.returnValue = message; // Tarayıcıların çoğu bu değeri göstermez ama mesajın çalışmasını sağlar
    return message; // Tarayıcının mesajı göstermesini sağlayan satır
  };

  const handleRouteChange = (url) => {
    const confirmMessage = intl.formatMessage({
      id: 'exitWarning',
      defaultMessage: 'Sayfadan çıkmak istediğinize emin misiniz?',
    });

    if (!window.confirm(confirmMessage)) {
      router.events.emit('routeChangeError');
      throw "Route change aborted";
    }
  };

  // Sayfa ilk yüklendiğinde ve sonrasında tarayıcı penceresi kapatılmaya çalışıldığında uyarı
  window.addEventListener('beforeunload', handleBeforeUnload);

  // Next.js ile sayfa içi yönlendirmelerde uyarı
  router.events.on('routeChangeStart', handleRouteChange);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    router.events.off('routeChangeStart', handleRouteChange);
  };
}, [router]);
 */
const handleKeyDown = (e) => {
  if (e.key === 'Enter' && e.shiftKey) {
    const { selectionStart, selectionEnd, value } = e.target;
    const newValue =
      value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd);

    setUserQuestion(newValue);

    setTimeout(() => {
      e.target.selectionStart = selectionStart + 1;
      e.target.selectionEnd = selectionStart + 1;
    }, 0);

    e.preventDefault();
  } else if (e.key === 'Enter' && !e.shiftKey) {
    if (!responseLoading) {
      send_llm();
    }
    e.preventDefault();
  }
};
const handlePlaceholderChange = (e) => {
  const newValue = e.target.value;
  if (newValue.length <= 50) {
    setPlaceHolder(newValue);
  } else {
    toast.current.show({ 
      severity: 'warn', 
      summary: intl.formatMessage({ id: "toast.warning" }), 
      detail: intl.formatMessage({ id: "toast.placeholder.maxLength" })
    });
  }
};
  useEffect(() => {

    const path = window.location.pathname;

    // URL yolunu parçalarına ayırır ve son kısmı alır
    const segments = path.split('/');
    const lastPart = segments[segments.length - 1];
    const user_temp_id_split = lastPart.split('=')
    const user_temp_id = user_temp_id_split[user_temp_id_split.length - 1]
    const get_templates = async () => {
      try {
        const response = await fetch(`/api/get_template_settings/${lastPart}`);
        const data = await response.json();
        console.log(data.get_settings);
        setGetSettings(data.get_settings);
        console.log(data.get_settings.name);
        setName(data.get_settings.name);
         setGreetingType(data.get_settings.greeting_type);
        setTemplateid(data.get_settings.template_id);
        
        setUserTemplateid(user_temp_id); // Bu değişken JSON'da yok, muhtemelen başka bir yerden geliyordur
        setMessage(data.get_settings.greeting_message);
        setPlaceHolder(data.get_settings.user_input_placeholder);
        data.get_settings.name === 'Custom Wizard' ? setIsCustomWizard(true) : setIsCustomWizard(false);
        data.get_settings.name === 'Custom Wizard' ? setButtonNames(['Setup']) : setButtonNames(['Setup', 'Instructions',"Plugins"]);
        setValue(data.get_settings.creativity_level);
        setDisplaydisc(data.get_settings.display_disclosure);
        setInputMessage(data.get_settings.instructions)
        setSelectedModel(data.get_settings.model_id)
        setImageUrl(data.get_settings.image_url)
        try {
          const response = await fetch('/api/get_image/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                  user_id: list[0],
                  "imageurl" :data.get_settings.image_url
              })
          });
  
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
  
          const imageBlob = await response.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);

          // Resmi göstermek için state'e kaydet
          setImageUrl(imageObjectURL);
          setImageName(data.get_settings.image_url)
          
      } catch (error) {
          console.error('Error creating image:', error);
      }
        
        setMessageData(prevData => [{
          ...prevData[0],
          header: data.get_settings.name,
          message: data.get_settings.greeting_message
        }]);
      } catch (e) {
        console.error(e);
      }
    };
    get_templates();
  }, []);

  useEffect(() => {
    if(userquestion.length > 0){
      setSendButtonDisabled(false);
}else{
  setSendButtonDisabled(true);}

}, [userquestion]);

  useEffect(() => {
    const handleClearMessages = async () => {
      dispatch(clearMessages());
      const response = await fetch(`/reset_playground_session/?user_id=${encodeURIComponent(userID)}`, {
          method: 'POST',
          headers: {
            'api-key': 'bXJ8HGJpbNDtnZM8UAtO8w8SH_qTDEDZolvzIb9ldcU1',
            'Content-Type': 'application/json',
          }
        });
  
        const data = await response.json();
        console.log(data)
    };

    if(userID){
      handleClearMessages();
    }
  }, [userID]);

  useEffect(() => {
    const get_llm_model = async () => {
      try {
        const response = await fetch('/api/get_llm_model/');
        const data = await response.json();
        const llm_list = data.llm_list;
        setModel(llm_list);
        console.log(llm_list);
      } catch (e) {
        console.error(e);
      }
    };
    get_llm_model();
  }, []);

  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedTime = `${(hours % 12) || 12}:${minutes < 10 ? '0' : ''}${minutes}${ampm}`;
  const [messageData, setMessageData] = useState([
    {
      header: name,
      message: message,
      footer: 'This is an automated chatbot response.',
      time: formattedTime,
      owner: 'ai'
    },
   
  ]);
  
useEffect(() => {

  setMessageData(prevData => [{
    ...prevData[0],
    header: name,
  }])
}, [name]);
const messageEndRef = useRef(null);

// Mesaj eklendikten sonra otomatik olarak kaydır
useEffect(() => {
  scrollToBottom();
}, [messageData]); // Sadece 'messages' değiştiğinde çalışacak

const scrollToBottom = () => {
  messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
};




useEffect(() => {

  setMessageData(prevData => [{
    ...prevData[0],
    message: message,
  }])
}, [message]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };
  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const create_bot = async (event) => {
    const new_bot = {"name": name, "placeHolder": placeHolder, "greetingType": greetingType, "message": message, "inputMessage": inputMessage, "selectedModel": selectedModel, "value": value, "directive": directive, "user_id": list[3], "template_id": templateid,"user_template_id" : usertemplateid, "display_disc": display_disc  , "image_url" : imageName};
    try {
      const url = window.location.pathname
      const segments = url.split('/');
      const lastPart = segments[segments.length - 1];
    
      const response = await fetch(`/api/create_bot/${lastPart}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(new_bot)
      });
      const data = await response.json();
      if (data.success) {
        toast.current.show({ severity: 'success', summary:intl.formatMessage({ id: "toast.success" }), detail:data.message });
        
    
        setPlaceHolder(data.user_input_placeholder);
        setDirective(data.instructions);
      } else {
 
        toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:data.message });
     
      
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "toast.error" }), detail:error });
    
  
    }
  };

  const send_llm = async () => {
    setResponseLoading(true);
    setSendButtonDisabled(true);
    setMessageData(prevData => [
      ...prevData,
      {
        header: 'User',
        message: userquestion,
        footer: 'asdasdasdasdsa',
        time: formattedTime, // You might want to adjust the time format
        owner: 'me'
      }
    ]);
    setUserQuestion("")
    const set_active_asistan = async (userID, user_template_id) => {
      console.log(userID, user_template_id);
      try {
        const response = await fetch('/api/set_active_asistan/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "user_id": userID, "user_template_id": user_template_id })
        });
  
        const data = await response.json();
        setResponse(data.response);
      } catch (error) {
        console.error('Error setting active assistant:', error);
        setResponseLoading(false);
        setSendButtonDisabled(false);

      }
    };
  
    // Add the user's input message to the messageData state
   
  
    try {
      await set_active_asistan(list[3], usertemplateid);
  
      const response = await fetch(`${API_URL}/generate_playground_response`, {
        method: 'POST',
        headers: {
          'x-api-key': 'bXJ8HGJpbNDtnZM8UAtO8w8SH_qTDEDZolvzIb9ldcU',

          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "user_id": String(list[0]), "user_question": String(userquestion), "session_id": "" })
      });
  
      const data = await response.json();
      if (data) {
        const newMessage = {
          header: name,
          message: data.response,
          footer: 'This is an automated chatbot response.',
          time: formattedTime,
          owner: 'ai'
        };
        
        setMessageData(prevData => [...prevData, newMessage]); // Append new message to existing messages
      }
    } catch (error) {
      console.error('Error generating assistant response:', error);
    } finally {
      setResponseLoading(false);
    }
  };
  
  const create_image = async () => {
    setLoading(true);

    try {
        const response = await fetch('/api/create_image/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                user_id: list[0],
                name: name 
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data.image_url)
        setImageName(data.image_url)
        /* setImageUrl(data.image_url); */
        try {
          const response = await fetch('/api/get_image/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                  user_id: list[0],
                  "imageurl" :data.image_url
              })
          });
  
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
  
          const imageBlob = await response.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);

          // Resmi göstermek için state'e kaydet
          setImageUrl(imageObjectURL);
          
      } catch (error) {
          console.error('Error creating image:', error);
      }
    } catch (error) {
        console.error('Error creating image:', error);
    } finally {
        setLoading(false);
    }
};

  return (
    <>
    <style jsx>{`
      .setupSendButton{
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 16px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  background-color: #625BC4;
  color: white;
  transition: background-color 0.3s ease;
  margin-left: 10px;

}
.setupSendButton:hover {
  background-color: #5144a3;
 
}
.setupRightContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top:80px;
      height:inherit;
  background-color: #f0f0f5;
}

.setupRightContentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 60px 75px 100px 50px;
  background-color: #d7d6f7;
height:inherit;
 

}

.setupRightContentMessagesContainer {
  flex: 1;
  width: 100%;

  min-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 10px;
}


@media ( max-width: 768px) {
  .setupRightContainer {

    display: none;
-
}
}



 `}</style>

   
    <div className={`rbt-main-content mr--0 mb--0 expandedMain`}>
    <Toast ref={toast} />
    <ConfirmModal
show={showModal}
message={intl.formatMessage({
  id: 'exitWarning',
  defaultMessage: 'Sayfadan çıkmak istediğinize emin misiniz?',
})}
onConfirm={handleConfirm}
onCancel={handleCancel}
/>
      <div className="rbt-daynamic-page-content">
        <div className="rbt-dashboard-content center-width">
          <div className='setupApp'>
            <div className='setupLeftContainer'>
              <div className='setupSectionsContainer'>
                {buttonNames.map((buttonName, index) => (
                  <button
                    key={index}
                    className={selectedButton === index ? 'setupSelected' : ''}
                    onClick={() => handleButtonClick(index)}
                  >
                    {buttonName}
                  </button>
                ))}
              </div>
              <div className='setupContentContainer'>
                {selectedButton === 1 ? (
                          // Render this div when Instructions is selected
                          <div className='instructionsContentContainer'>
                          <p className='setupDirectiveExpText'><FormattedMessage id="instructions.text" /></p>
                        
                        
                        <div className='setupDirectiveContainer'>
                        <textarea

                        className='setupDirectiveInputPlace'
                        value={inputMessage}
                        onChange={(e) => {
                          e.preventDefault();
                          setInputMessage(e.target.value)}}
                      />
                        </div>
                      

                        </div>
                ): selectedButton === 0 ? (
                  // Default setup content
                  <>
                      <div className='setupImageContainer' style={{ display: "flex", flexDirection: "row" ,}}>
                        {
                          loading ? 

                          (
                            <TailSpin color="#625BC4" height={100} width={100} />

                          

                          )

                          :

                          (
                            <div style={{ width: 100, height: 100, borderRadius:50, border: "solid #699DE9 1px ",justifyContent:'center',alignItems:'center',
                              display:'flex'
                                     }}>
                                      {imageUrl &&
                                    
                        
                                      (
                                        <img src={imageUrl} alt='Generated' style={{ width: '100%', height: '100%'
                                          ,borderRadius:50, border: "solid #699DE9 1px "
                                         }} />
                                      )
            
                                     }
                                    </div>
                          )
                        }
                       
                        <div style={{marginLeft:5,flex:1,display:'flex',flexDirection:'column'}}>
                          <p className='setupNameInputText'><FormattedMessage id="setup.bot.name" /> </p>
                          <input
                            className='setupNameInputPlace'
                            type='text'
                            value={name}
                            placeholder={intl.formatMessage({ id: "setup.bot.name.placeholder" })} 
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        
                        <div>
                         
                        </div>
                        
                      </div>
                      <input
                            className='create-image'
                            value={intl.formatMessage({ id: "setup.create.logo" })}
                            type='button'
                            onClick={create_image}
                          />
                    <div className='setupNameInputContainer' style={{marginTop:10}}>
                      <p className='setupNameInputText'><FormattedMessage id="setup.welcome.text" /> </p>
                      <input className='setupNameInputPlace' type='text' value={message} placeholder={intl.formatMessage({ id: "setup.welcome.placeholder" })} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                    <div className='setupNameInputContainer'>
                      <p className='setupNameInputText'><FormattedMessage id="setup.default.text" /> </p>
                      <input className='setupNameInputPlace'     maxLength={60} 
 value={placeHolder} type='text' placeholder={intl.formatMessage({ id: "setup.default.placeholder" })}     onChange={handlePlaceholderChange}
 />
                    </div>
                  
                    <div className='setupLine'></div>
                    <div className='setupModelSelectContainer'>
                      <p className='setupModelSelectText'><FormattedMessage id="setup.select.model" /></p>
                      <select className='setupModelSelect' value={selectedModel} onChange={handleModelChange}>
{/*                         <option value='' disabled> Select a model</option>
 */}                        {model.map((platform) => (
                          <option key={platform.id} value={platform.id}>{platform.modelname}</option>
                        ))}
                      </select>
                     
                    </div>
                   {
                      isCustomWizard && (
                        <div className='instructionsContentContainer'>
                        <p className='setupDirectiveExpText'><FormattedMessage id="instructions.text" /></p>
                      
                       
                      <div className='setupDirectiveContainer'>
                       <textarea
      
                      className='setupDirectiveInputPlace'
                      value={inputMessage}
                      onChange={(e) => {
                        e.preventDefault();
                        setInputMessage(e.target.value)}}
                    />
                      </div>
                    
      
                      </div>
                      )
                   }
                    <div className="setupSliderContainer">
                      <div className="setupLabelContainer">
                        <span className="setupLabel"><FormattedMessage id="setup.creativity" /></span>
                        <button className="setupResetButton" onClick={() => setValue(0.7)}><FormattedMessage id="setup.reset" /></button>
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={value}
                        onChange={handleChange}
                        className="setupSlider"
                        style={{ '--value': value }}
                      />
                      <div className="setupValueContainer">
                        <span className="setupValueLabel"><FormattedMessage id="setup.predictable" /></span>
                        <span className="setupValue">{value}</span>
                        <span className="setupValueLabel"><FormattedMessage id="setup.random" /></span>
                      </div>
                    </div>
                    <div>
                      <button className='setupSaveButton' onClick={create_bot}>
                        <p style={{color:'white'}} className='setupSaveButtonText'><FormattedMessage id="setup.save.changes" /></p>
                      </button>
                    </div>
                  </>
                ):(<Plugins/>)}
              </div>
            </div>
            <div className='setupRightContainer'>
           
              <div className='setupRightContentContainer'>
                <div className='setupRightContentMessagesContainer'  >
                  {messageData.map((item, index) => (
                    <MessageItem welcomeText={message} enterName={name} key={index} item={item} />
                  ))}
                  {response && (
                    <div>
                      <p>{response}</p>
                    </div>
                  )}
                  <div ref={messageEndRef} />
                </div>
                <div className="setupTextInputContainer">
                  <input
                  value={userquestion}
                    type="text"
                    onKeyDown={handleKeyDown}
                    className="setupTextInput"
                    placeholder={placeHolder}
                    onChange={(e) => setUserQuestion(e.target.value)}
                  />
                  <button   disabled={responseLoading || sendButtonDisabled} style={{backgroundColor:responseLoading || sendButtonDisabled ? "grey" :"#625BC4" }}  onClick={send_llm} className="setupSendButton">
                    {
                      responseLoading ? 
                      (
                        <div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <FormattedMessage id="setup.answering" />
                          <div  style={{marginLeft:5}}>

                        
                        <BallTriangle color="white" height={20} width={20} />
                        </div>
                        </div>
                      )
                      :
                      (
                        <p className='setupSendButtonText'>  <FormattedMessage id="setup.send" /> </p>
                      )
                    }
                   </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CodeGenerator;
