import React,{ useEffect , useState ,useRef}from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import rehypeReact from 'rehype-react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import remarkGfm from 'remark-gfm';

const PreWithCopyButton = ({ children, ...props }) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const getCodeText = (children) => {
      let text = '';
      React.Children.forEach(children, (child) => {
        if (typeof child === 'string') {
          text += child;
        } else if (React.isValidElement(child) && child.props.children) {
          text += getCodeText(child.props.children);
        }
      });
      return text;
    };
  
    const codeText = getCodeText(children);
  
    const handleCopy = () => {
      navigator.clipboard.writeText(codeText);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000); // 2 saniye sonra tik işaretini kaybet
      
    };
  
    return (
      <div style={{ position: 'relative' }}>
        <CopyToClipboard text={codeText} onCopy={handleCopy}>
        <button className="code-copy-btn" style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: '#7d7d7d',
          border: 'none',
          cursor: 'pointer',
          padding: '2px 5px',
          borderRadius: '5px',
          zIndex: 1,
        }}> {copySuccess  ? <span style={{ color: '#06D001' }}>✔ Copied</span> 
      :
      <i className="feather-copy"></i>
      }
</button>
        </CopyToClipboard>
        <pre {...props}>
          {children}
        </pre>
      </div>
    );
  };
  

const MessageItem = ({item,enterName,welcomeText,}) => {
    const containerClass = item.owner === 'ai' ? 'chat-container ai' : 'chat-container user';

    return (
        <div className={containerClass}>
            {item.owner === "ai" ? (
                <React.Fragment>
                    <div className="chat-header">{item.header}</div>
                    <div className="chat-message">
                    <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    children={item.message} 
                    components={{
                      pre:PreWithCopyButton,
                      code({ inline, className, children, ...props }) {
                        return (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                    />
                    
                 {/*        <p>{item.message}</p> */}
                    </div>
                    <div className="chat-time">{item.time}</div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="chat-message">
                        <p>{item.message}</p>
                    </div>
                    <div className="chat-time">{item.time}</div>
                </React.Fragment>
            )}
        </div>
    );
}

export default MessageItem;
