import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormattedMessage } from 'react-intl';
import DashboardItem from "../../data/header.json";

import menuImg from "../../public/images/menu-img/menu-img-2.png";
import { useAppContext } from "@/context/Context";
import useList from '@/components/isAuth/isAuth';

const Nav = ({messages}) => {
  const [isCorporate, setIsCorporate] = useState(false); // Kurumsal kontrolü için state
  const router = useRouter();
  const { showItem, setShowItem } = useAppContext();
  const list = useList(); 

  useEffect(() => {
    // Admin değeri "kurumsal" mı kontrol et
    if (list[4] === 'kurumsal') {
      setIsCorporate(true);
    } else {
      setIsCorporate(false);
    }
  }, [list]);

  const isActive = (href) => router.pathname === href;
  
  const logout = async (e) => {
    try {
      const response = await fetch('/api/logout/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "selam" :"selam" })
      });
      const data = await response.json();
      console.log(data);
      localStorage.removeItem('mytoken');
      window.location.href = "/";
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  return (
    <>
      <ul className="mainmenu">
        {list[1] ? (
          <>
            <li className="llm-dropdown">
              <Link href="/chat"><FormattedMessage id="Nav.chat" /></Link>
              <ul className="llm-dropdown-content">
                <li style={{borderBottom:"1px solid #e1a9a9"}}> <Link href="/chat"><FormattedMessage id="Nav.chat" /></Link></li>
                <li style={{borderBottom:"1px solid #e1a9a9"}}> <Link href="/chat-bot"><FormattedMessage id="Nav.chat.bot" /></Link></li>
                <li style={{borderBottom:"1px solid #e1a9a9"}}> <Link href="/image-generator"><FormattedMessage id="Left-nav.imageGenerator" /></Link></li>
                <li style={{borderBottom:"1px solid #e1a9a9"}}><Link href="/templates"><FormattedMessage id="Nav.select.template" /></Link></li>
                <li style={{borderBottom:"1px solid #e1a9a9"}}> <Link href="/my-assistants"><FormattedMessage id="Nav.my.assistants" /></Link></li>
              </ul>
            </li>
            <li>
              <Link href="/blog">Blog</Link>
            </li>
            <li>
              <Link href="/plans-billing"><FormattedMessage id="Left-nav.pricing" /></Link>
            </li>
            {isCorporate && (
              <li>
                <Link href="/admin-panel"><FormattedMessage id="Left-nav.admin" /></Link>
              </li>
            )}
            <li>
              <Link href="/" onClick={logout}><FormattedMessage id="Nav.logout" /></Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link href="/blog">Blog</Link>
            </li>
            <li>
              <Link href="/pricing"><FormattedMessage id="Left-nav.pricing" /></Link>
            </li>
            <li>
              <Link href="/AuthPage"><FormattedMessage id="Nav.signIn" /></Link>
            </li>
            <li style={{display:'none'}}>
              <Link href="/chat">Chat</Link>
            </li>
            <li style={{display:'none'}}>
              <Link href="/profile-details">Profile</Link>
            </li>
          </>
        )}
      </ul>
    </>
  );
};

export default Nav;
