import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

const booleanSlice = createSlice({
  name: 'boolean',
  initialState,
  reducers: {
    toggleBoolean: (state) => {
      state.value = !state.value;
    },
    setBooleanTrue: (state) => {
      state.value = true;
    },
    setBooleanFalse: (state) => {
      state.value = false;
    },
  },
});

export const { toggleBoolean, setBooleanTrue, setBooleanFalse } = booleanSlice.actions;
export default booleanSlice.reducer;
