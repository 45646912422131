import React, { useState } from 'react';
import Image from 'next/image';

const Maintenance = () => {
    const [language, setLanguage] = useState('en');

    const content = {
        tr: {
            title: 'Bakımdayız',
            message: 'Şu anda bakım çalışmaları yapıyoruz. Lütfen daha sonra tekrar deneyin.',
            buttonText: 'Anladım'
        },
        en: {
            title: 'Under Maintenance',
            message: 'We are currently undergoing maintenance. Please try again later.',
            buttonText: 'Got it'
        }
    };

    return (
        <div className="maintenance">
            <div className="content">
                <Image height={250} width={250} className='image' src="/images/screens/bakim.png" alt="Maintenance" />
                <Image height={250} width={250} className='image' src="/images/logo/logo-big.png" alt="Maintenance" />

                <h1>{content[language].title}</h1>
                <p>{content[language].message}</p>
                
                {/* Custom Dropdown */}
                <div className="language-select">
                    <div className="selected-language" onClick={() => document.getElementById('dropdown').classList.toggle('show')}>
                        {language === 'tr' ? (
                            <>
                                <Image height={20} width={20} src="/images/icons/turkey.png" alt="Turkey Flag" />
                                <span>Türkçe</span>
                            </>
                        ) : (
                            <>
                                <Image height={20} width={20} src="/images/icons/en-us.png" alt="US Flag" />
                                <span>English</span>
                            </>
                        )}
                    </div>
                    <div id="dropdown" className="dropdown-content">
                        <div onClick={() => { setLanguage('tr'); document.getElementById('dropdown').classList.remove('show'); }}>
                            <Image height={20} width={20} src="/images/icons/turkey.png" alt="Turkey Flag" />
                            <span>Türkçe</span>
                        </div>
                        <div onClick={() => { setLanguage('en'); document.getElementById('dropdown').classList.remove('show'); }}>
                            <Image height={20} width={20} src="/images/icons/en-us.png" alt="US Flag" />
                            <span>English</span>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .maintenance {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    background-color: #FFFFFF;
                    text-align: center;
                }
                .content {
                    padding: 100px;
                    background: #FFF;
                    border-radius: 10px;
                    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
                }
                h1 {
                    color: #123E7B;
                    margin-top: 20px;
                }
                p {
                    color: #123E7B;
                    font-size: 18px;
                }
                .image {
                    margin-bottom: 20px;
                }
                .start-button {
                    margin-top: 20px;
                    padding: 10px 20px;
                    font-size: 16px;
                    color: #FFF;
                    background-color: #123E7B;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .start-button:hover {
                    background-color: #0A2A5E;
                }
                .language-select {
                    position: relative;
                    display: inline-block;
                    margin-top: 20px;
                    cursor: pointer;
                }
                .selected-language {
                    padding: 10px;
                    border: 1px solid #123E7B;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .dropdown-content {
                    display: none;
                    position: absolute;
                    background-color: #f9f9f9;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                    z-index: 1;
                    border-radius: 5px;
                    margin-top: 5px;
                }
                .dropdown-content div {
                    color: black;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .dropdown-content div:hover {
                    background-color: #f1f1f1;
                }
                .show {
                    display: block;
                }
            `}</style>
        </div>
    );
};

export default Maintenance;
