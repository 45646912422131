import React from "react";
import Head from "next/head";

const Loading = () => {
  return (
    <>
    <Head>
        <link rel="icon" href="/llmwizard1.ico" />
    </Head>
      <div className="preloader">
        <div className="loader">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </>
  );
};

export default Loading;
