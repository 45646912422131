import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export default function useList() {
  const [list, setList] = useState([]);
  const router = useRouter();
  const [hasShownAlert, setHasShownAlert] = useState(false);
  const [language, setLanguage] = useState(() => {
    if (typeof window !== "undefined") {
      const savedLanguage = localStorage.getItem("language");
      return savedLanguage || "en";
    }
    return "en";
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("mytoken");
        
        if (token) {
          const response = await fetch('/api/post/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
          });

          const data = await response.json();
          
          if (isMounted) {
            if (data.iserror) {
              if (data.another && !hasShownAlert) {
                const message = language === "en" 
                  ? "Account has been logged in another device!"
                  : data.message;
                alert(message);
                setHasShownAlert(true);
              }
              
              localStorage.removeItem("mytoken");
              router.push('/AuthPage');
            } else {
              const { first_name, email, id, role } = data.user;
              setList([first_name, true, email, id, role]);
            }
          }
        } else {
          if (isMounted) {
            setList([null, false]);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [router, language, hasShownAlert]);

  return list;
}