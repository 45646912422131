// src/context/CookieContext.js
import React, { createContext, useState, useEffect } from 'react';

// CookieContext oluşturuyoruz
export const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    // Çerez kabul durumunu localStorage'dan kontrol et
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') { // Değişkeni string olarak kontrol et
      setCookiesAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true'); // Değerin string olduğundan emin ol
    setCookiesAccepted(true);
  };

  return (
    <CookieContext.Provider value={{ cookiesAccepted, acceptCookies }}>
      {children}
    </CookieContext.Provider>
  );
};