import Link from "next/link";
import React, { useEffect } from "react";
import sal from "sal.js";
import { FormattedMessage   } from 'react-intl';
const Compare = () => {
  useEffect(() => {
    sal();
  }, []);
  return (
    <>
      <div className="rainbow-pricing-detailed-area mt--30 rainbow-section-gapTop">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-left mb--30"
              data-sal="slide-up"
              data-sal-duration="400"
              data-sal-delay="150"
            >
              <h3 className="title w-600 mb--0">  <FormattedMessage id="price.detailed.compare" /> </h3>
            </div>
          </div>
        </div>
        <div className="row row--15">
          <div className="col-lg-12">
            <div className="rainbow-compare-table style-1">
              <table className="table-responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th className="sm-radius-top-left"> <FormattedMessage id="price.free" /> </th>
                    <th className="style-prymary"> <FormattedMessage id="price.standard" /> </th>
                    <th className="style-prymary sm-radius-top-right"> <FormattedMessage id="price.professional" /> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="heading-row">
                    <td>
                      <h6><FormattedMessage id="price.detailed.row1" /></h6>
                    </td>
                    <td><FormattedMessage id="price.detailed.free.price" /></td>
                    <td><FormattedMessage id="price.detailed.standard.price" /></td>
                    <td><FormattedMessage id="price.detailed.professional.price" /></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row2" /></td>
                    <td><FormattedMessage id="price.detailed.free.templates" /></td>
                    <td><FormattedMessage id="price.detailed.standard.templates" /></td>
                    <td><FormattedMessage id="price.detailed.professional.templates" /></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row3" /></td>
                    <td><FormattedMessage id="price.detailed.free.credits" /></td>
                    <td><FormattedMessage id="price.detailed.standard.credits" /></td>
                    <td><FormattedMessage id="price.detailed.professional.credits" /></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row4" /></td>
                    <td><FormattedMessage id="price.detailed.free.history" /></td>
                    <td><FormattedMessage id="price.detailed.standard.history" /></td>
                    <td><FormattedMessage id="price.detailed.professional.history" /></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row5" /></td>
                    <td><FormattedMessage id="price.detailed.free.speed" /></td>
                    <td><FormattedMessage id="price.detailed.standard.speed" /></td>
                    <td><FormattedMessage id="price.detailed.professional.speed" /></td>
                  </tr>
                  <tr className="heading-row">
                    <td>
                      <h6><FormattedMessage id="price.detailed.row6" /></h6>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row7" /></td>
                    <td>
                        <i style={{color:'red'}} className="feather-x"></i>
                    </td>
                    <td>
                        <i style={{color:'red'}} className="feather-x"></i>
                    </td>
                    <td>
                        <i style={{color:'green'}} className="feather-check"></i>
                    </td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row8" /></td>
                    <td>
                        <i style={{color:'red'}} className="feather-x"></i>
                    </td>
                    <td>
                        <i style={{color:'red'}} className="feather-x"></i>
                    </td>
                    <td>
                        <i  style={{color:'green'}}className="feather-check"></i>
                    </td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="price.detailed.row9" /></td>
                    <td>
                        <i style={{color:'red'}} className="feather-x"></i>
                    </td>
                    <td>
                        <i style={{color:'red'}} className="feather-x"></i>
                    </td>
                    <td>
                        <i style={{color:'green'}} className="feather-check"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Compare;
