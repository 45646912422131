import React from "react";

import AccordionItem from "./AccordionItem";
import { FormattedMessage } from 'react-intl';
import third_image from '../../public/images/screens/faq2.png';
import Image from "next/image";

const Accordion = ({ isHead  }) => {
 
  isHead = true

  return (
    <>
      <div className="rainbow-accordion-area rainbow-section-gap">
        <div className="container faq-container" style={{borderRadius:"0 80px 80px 80px",border:"0px solid #112", padding:"50px 10px"}}>
          {isHead ? (
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div
                  className="section-title text-center"
                  data-sal="slide-up"
                  data-sal-duration="700"
                  data-sal-delay="100"
                >
                  
                  <h2 style={{color:"#386CDC"}}>
                    <FormattedMessage id="Q.title"  />
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

            <div className="row mt--35 row--20">
                <div  style={{display:"flex",justifyContent:"space-between"}}>
                    <div className="faq-leftside" style={{justifyContent:'center',alignItems:'center',display:'flex',paddingLeft:50}}>

                   <Image src={third_image} alt="faq" width={700} height={700} />
                    </div>
                    <div className="faq-faq2 faq-rightside" style={{marginRight:20}}>
                      <AccordionItem />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
