import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { CookieContext } from '@/context/CookieContext'; // Context'i import ediyoruz

const Cookie = () => {
  const { cookiesAccepted, acceptCookies } = useContext(CookieContext); // Context'ten state ve fonksiyonu alıyoruz

  useEffect(() => {
    if (cookiesAccepted) {
      // Eğer çerezler kabul edilmişse Analytics'i başlat ve console'u devre dışı bırak
      enableAnalytics();
      disableConsoleLogs();
    }
  }, [cookiesAccepted]); // cookiesAccepted değiştiğinde çalışacak

  const enableAnalytics = () => {
    // Google Analytics kodunu burada başlatabilirsiniz
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-4EMBSQJE87');
  };

  const disableConsoleLogs = () => {
    console.log = function() {};
    console.warn = function() {};
    console.error = function() {};
  };

  if (cookiesAccepted) {
    return null; // Çerezler kabul edilmişse bileşen görünmez
  }

  return (
    <div style={styles.cookieNotice}>
      <img 
        src="/images/logo/cookies.png" 
        alt="cookie icon" 
        style={styles.cookieIcon} 
      />
      <h2 style={styles.title}><FormattedMessage id="cookie.notice"/></h2>
      <p style={styles.text}>
        <FormattedMessage id="cookie.uses"/>
      </p>
      <div style={styles.buttons}>
        <button onClick={acceptCookies} style={styles.acceptButton}>
          <FormattedMessage id="cookie.accept"/>
        </button>
        <a href="/privacy-policy" style={styles.learnMore}>
          <FormattedMessage id="cookie.learn"/>
        </a>
      </div>
    </div>
  );
};

const styles = {
  cookieNotice: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '400px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    zIndex: 1000,
  },
  cookieIcon: {
    width: '50px',
    marginBottom: '15px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#333',
  },
  text: {
    fontSize: '14px',
    marginBottom: '20px',
    color: '#555',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  acceptButton: {
    backgroundColor: '#d87f28',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  learnMore: {
    color: '#d87f28',
    textDecoration: 'none',
    fontSize: '14px',
    alignSelf: 'center',
  },
};

export default Cookie;
