import React, { useState,useEffect,useRef } from 'react';
import Slider from "react-slick";
import myImage1 from '../../public/images/screens/my-boats-vert.png';
import task from '../../public/images/screens/setup-with-logo.png';
import myImage3 from '../../public/images/screens/chat.png';
import girlHeadset from '../../public/images/screens/instruc.png';
import pcTools from '../../public/images/screens/instruc-advenced.png';
import girlOnPc from '../../public/images/home-images/girl-on-pc.png';
import phoneMessages from '../../public/images/screens/six.jpg';
import messagesPicture from '../../public/images/screens/seven.jpg';
import chatbotsPeople from '../../public/images/screens/eight.jpg';
import talkingWithAi from '../../public/images/home-images/talkingWith-ai.png';
import manyBoats from '../../public/images/home-images/talking-with-girl.png';
import robotHelping  from '../../public/images/home-images/robothelping.png';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";

const Split = ({ messages }) => {
  const [learnMoreClicked, setLearnMoreClicked] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState('orange');
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionTop = sectionRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight / 2) {
          setBackgroundColor('#CCC');
        } else {
          setBackgroundColor('#F7F7F7');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call it once on mount to set the initial color

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // 3 seconds

  };

  
  
  return (
    <>
  <style jsx>{` 
  .transition-div {
    transition: all 2s ease-in-out;
    overflow: hidden;
  }
  .rainbow-section-gap {
    padding: 0px 0;
  }
  .container {
    padding-right: 25px;
    padding-left: 25px;
  }
  .header-text {
    font-size:clamp(22px, 1.8vw, 40px);   
     font-weight: bolder;
     margin-top: 0px;
    background-image: linear-gradient(to right, #3363E2, #699DE9, #9DD1EF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .sub-header-text {
    font-size: 16px;
    color: black;
    margin-top: 0px;
  }
  .link-text {
    font-size: 16px;
    color: #3A75E8;
  }
  .section {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 15px;
  }
  .section-half {
    display: flex;
    flex: 1;
    flex-direction: row; /* Varsayılan yön, büyük ekranlarda row */
    margin-top: 50px;
  }

  @media (max-width: 767px) { /* Küçük ekranlar için */
    .section-half {
      display: flex;
    flex: 1;
    flex-direction: column; /* Varsayılan yön, büyük ekranlarda row */
    margin-top: 50px;
    }
    .section-item-half{
      width: 100%;
      margin-top:25px;
    }
    .section{
      display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 5px;
    }
    .section-item{
      width: 100%;
      margin-top:25px;
      padding-top: 5px;

    }
    .section-half-bottom{
      padding-right:0px;
      padding-left:0px;
      justify-content: center;
    align-items: center;
    display: flex;
    }
  }
  .section-half-bottom {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-right: 150px;
    padding-left: 150px;
  }
  .section-item {
    margin-right: 25px;
    border-radius: 30px;
  
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    padding-top: 25px;
    border: 2px solid #699DE9;
    align-items: center;
    transition: background-color .5s ease-in-out; /* Add transition for background-color */
    cursor: pointer;
  }
  .section-item:hover {
    background-color: #699DE9;
  }
  .section-item:hover .highlighted-text,
  .section-item:hover .highlighted-text-blue,
  .section-item:hover .highlighted-text-white {
    color: black; /* Change this to the desired color */
  }
  .section-item-half {
    background-color: ${backgroundColor};
   
    margin-right: 25px;
    padding: 35px;
    border-radius: 30px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    transition: background-color 2s ease-in-out; /* Add transition for background-color */
  }
  .transition-div {
    transition: all 0.5s ease; /* Animasyon geçişi */
    overflow: hidden;
  }
  .highlighted-text {
    font-size: 19px;
    line-height: 1.2105263158;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #3A75E8;
    padding-left: 25px;
    padding-right: 15px;
  }
  .highlighted-text-blue {
    font-size: 19px;
    line-height: 1.2105263158;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #3A75E8;
    padding-left: 25px;
    padding-right: 15px;
  }
  .highlighted-text-white {
    font-size: 19px;
    line-height: 1.2105263158;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: '#fff';
    padding-left: 25px;
    padding-right: 15px;
  }
  .content-text {
    font-size: 16px;
    color: black;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 15px;
  }
  .content-text-white {
    font-size: 16px;
    color: white;
    font-weight: bold;
  }
  .image {
    width: 100%;
    max-height: 300px;
    border-radius: 20px;
   
  }
  .button {
    background-color: black;
    color: white;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 30px;
    font-size: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
  
    display: flex;
    transition: all 2s ease-in-out;
  }
  .button-clicked {
    background-color: white;
    color: black;
  
  }
  .section-item-bottom{

  }

`}</style>


      <div className="rainbow-section-gap">
        <div className="container">
         

          <div className="section">
            <div className="section-item">
              <div>
                <div className="highlighted-text">Smart Assistant for Every Task</div>
                <div className="content-text">Speed up your daily workflow and boost your productivity. Our application provides automated task management, always at your side.</div>
              </div>
            </div>

            <div className="section-item">
              <div>
                <div className="highlighted-text">Save Time, Simplify Your Tasks</div>
                <div className="content-text">Save time by automating your routine tasks and focus on what matters most. Handle your tasks instantly with our smart chatbots.</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, borderRadius: 30 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end',flex:1 }}>
                </div>
              </div>
            </div>

            <div className="section-item">
            <div>
                <div className="highlighted-text">Easy Integration, Instant Solutions</div>
                <div className="content-text">Evolvechat offers seamless integration with your existing systems. Connect with just one click and optimize your business processes.</div>
              </div>
             
            </div>
          </div>

          <div className="section-half">
            <div className="section-item-half" ref={sectionRef}>
              <div>
                <div className="highlighted-text-white" style={{color:'white'}}>Personalized Communication Experience</div>
                <div className="content-text" style={{ maxWidth: 550 }}>With the instuctions tab, you can set which features your personal assistant should have and what kind of tone he or she should adopt.</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Image src={girlHeadset} alt="template" className="image"style={{borderRadius:20,marginTop:20}} />
              </div>
            </div>

            <div className={`transition-div section-item-half ${learnMoreClicked ? 'button-clicked' : ''}`} style={{ backgroundColor: learnMoreClicked ? 'orange' : 'white',border:learnMoreClicked ? 'none' : '2px solid #699DE9', }}>
              <div style={{ paddingRight: 10, flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div className="highlighted-text-blue" style={{ color:learnMoreClicked ?  'white' : "orange" }}>{learnMoreClicked ? "Is that not enough for you?" : "Here is the magic..."}</div>
                <div className={`content-text ${learnMoreClicked ? 'content-text-white' : ''}`}>
                  {learnMoreClicked ? 'No problem, click on the plus button below to see the magic. With the Advanced feature you can personalize and customize your personal assistant in more detail.'
                    : 'With Advanced, you can customize your personal assistant and tell it how to approach users.'}
                </div>
                {!learnMoreClicked &&
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <Image src={pcTools} alt="template" className="image" style={{marginTop: 20,borderRadius:20,maxHeight:425 }} />
                  </div>
                }
              </div>
              <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', flex: 1, alignItems: 'flex-end', paddingRight: 35, }}>
                <button className={`button ${learnMoreClicked ? 'button-clicked' : ''}`} onClick={() => {
                  setLearnMoreClicked(!learnMoreClicked);
                }}>+</button>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};


/*

<div style={{ display: 'flex', flex: '1', flexDirection: 'row', marginTop: 50 }}>
            <div style={{ backgroundColor: '#F5F7F8', minHeight: 750, width: '35%', marginRight: '25px', borderRadius: 30, flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
              <div style={{ paddingLeft: 35, paddingTop: 25, paddingRight: 10 }}>
              <div style={{ fontSize: 19,lineHeight: 1.2105263158,fontWeight: 600,letterSpacing:.1,color:"#3A75E8"}}>New look for Ai generators</div>
                <div style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>Discover the future of AI with our cutting-edge generators, now more powerful and intuitive than ever. </div>
              </div>

              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

              <Image src={manyBoats} alt='template' style={{ width: '100%', minHeight: 400,maxHeight:500, borderRadius: 20 }} />
              </div>
            </div>

            <div className={`transition-div`} style={{ backgroundColor: learnMoreClicked ? 'orange' : '#3A75E8', minHeight: 750, maxHeight:750, width: '65%', marginRight: '25px', borderRadius: 30, flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
              <div style={{  paddingTop: 25,  minHeight: 450, flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div style={{ paddingLeft: 35,paddingRight: 35,fontSize: 19,lineHeight: 1.2105263158,fontWeight: 600,letterSpacing:.1,color:"White"}}>Advanced Features              </div>
                <div style={{ fontSize: 16,paddingLeft: 35,paddingRight: 35, color: learnMoreClicked ? 'white' : 'white', fontWeight: 'bold',width:500 }}>{learnMoreClicked ? 'Take your chatbots to the next level with advanced features such as multi-step conversations, conditional logic. These capabilities allow you to create sophisticated chatbot workflows that can handle complex scenarios, ensuring your automation strategy is robust and versatile.' : 'Easily set up automations with our intuitive interface, no technical skills needed.'}</div>
                {
                  learnMoreClicked 
                  ?
                  null
                  :
                  
                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'end',flex:1}}>
                <Image src={robotHelping} alt='template' style={{ width: '100%', minHeight: 400,maxHeight:500,marginTop:125, borderRadius: 20 }} />
                </div>
                }
                              </div>
             
              <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', flex: 1, alignItems: 'flex-end', paddingRight: 35, paddingBottom: 35 }}>
                <button className={`transition-div`}  style={{ backgroundColor: learnMoreClicked ? 'white' : 'black', color: learnMoreClicked ? 'black' : 'white', border: 'none', height: 50, width: 50, borderRadius: 30, fontSize: 30, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} onClick={() => {
                  setLearnMoreClicked(!learnMoreClicked);
                }}>+</button>
              </div>
            </div>
          </div>
*/

export default Split;
