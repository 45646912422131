import React, { useRef,useState, useEffect,useContext } from 'react';
import Image from "next/image";
import Link from "next/link";
import sal from "sal.js";
import boxedLogo from "../../public/images/logo/logo.png";
import logoLight from "../../public/images/logo/logo-light.png";
import google from "../../public/images/sign-up/google.png";
import facebook from "../../public/images/sign-up/facebook.png";
import PageHead from "@/pages/Head";
import { ThemeContext } from '@/context/ThemeContext';
import { useRouter } from 'next/router';
import useList from '@/components/isAuth/isAuth';
import Info from  '@/components/Info/Info';
import { FormattedMessage,useIntl  } from 'react-intl';
import { LineWave } from 'react-loader-spinner'

import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact tema CSS
import 'primereact/resources/primereact.min.css';         // PrimeReact temel CSS
import 'primeicons/primeicons.css';  
        

export const useAppContext = () => {
  const [toggleAuth, setToggleAuth] = useState(false); // Varsayılan değer olarak false kullanılabilir
  return { toggleAuth, setToggleAuth };
}

const UserAuth = ({messages}) => {
  const { darkMode } = useContext(ThemeContext);
  const toast = useRef(null);
  const intl = useIntl();
  const list = useList();
  const { toggleAuth, setToggleAuth } = useAppContext();
  const router = useRouter();
  const [loading,setLoading] = useState(false)
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");
  infoMessage
  const handleCloseInfo = () => {
    setShowInfo(false);
  };
  useEffect(() => {
    sal();
    

    const cards = document.querySelectorAll(".bg-flashlight");
    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');

  
  
  
  const handleSignInSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      const response = await fetch('/api/signin/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password })
      });
      
      const data = await response.json();
  
      console.log(data)
      if(data.success){
        localStorage.setItem('mytoken' , data.mytoken)
        if (window.fbq) {
          window.fbq('track', 'CompleteRegistration');
        }
        setLoading(false)
        if(window !== undefined){
          window.location.href = "/"
        }
        
        //router.push("/chat")
      }else{
        console.log(data)
        toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:data.message });
        setLoading(false)

      }   
      
  }catch (error) {
      console.error('Error:', error);
      setLoading(false)
      console.log(error)

    }
  }


  
  const handleSignUpSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();

    if (password !== confirmPassword) {
     
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Passwords do not match' });
      setLoading(false)

    }else if (email.trim() === '' || password.trim() === '') {
      
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'You need to enter an email' });
      setLoading(false)

    }else if (password.trim() === '') {
      
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'You need to enter a password' });
      setLoading(false)

    }else if (name.trim().length<5){
    
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.error.username" }) });
      setLoading(false)

    }else if(/^\d+$/.test(name)){

      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Username must contain letters' });
      setLoading(false)

    }else if (password.length<5){
   
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Password cannot be less than 5 characters' });
      setLoading(false)

    }else if(/^\d+$/.test(password)){
  
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Password must contain letters' });
      setLoading(false)

    }else if(/^\d+$/.test(phone)){
    
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Phone Number must be number' });

      setLoading(false)

    }
  
  else{
    try {
      const response = await fetch('/api/signup/', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, password })
      });

      const data = await response.json();

      // Django'dan gelen cevabı konsola yazdır
      if(data.success){
    
        toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "toast.success" }), detail:data.message });

        setTimeout(() => {
          window.location.href = "/AuthPage";
        }, 2000); // 3 saniye bekledikten sonra yönlendirme
          
        setLoading(false)
      }else{
    
        toast.current.show({ severity: 'warn', summary:intl.formatMessage({ id: "toast.warning" }), detail:data.message });
        setLoading(false)
      }
    } catch (error) {
      console.error('Error:', error);
      toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "toast.error" }), detail:error });
      setLoading(false)

    }
  }
  };

  const resendVerificationEmail = async () => {
    if (email.trim() === ''){
    
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Please Fill The E-Mail İnput' });
    }else{

      setLoading(true);
      try {
        const response = await fetch('/api/send_verification_email_endpoint/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        
        const data = await response.json();

        toast.current.show({ severity: 'success', summary:intl.formatMessage({ id: "toast.success" }), detail:data.message });
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
      
        toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:'The email could not be sent.' });
        setLoading(false);
      }
    }
    
  };
  

  return (
    <>
   {/*    <PageHead title={`${toggleAuth ? "Log In" : "SignUp"}`} /> */}
      <div className="signup-area rainbow-section-gapTop-big" data-black-overlay="2">
      <Info message={infoMessage} show={showInfo} onClose={handleCloseInfo} />
      <Toast ref={toast} />
        <div className="sign-up-wrapper rainbow-section-gap">
          <div className="sign-up-box bg-flashlight">
            <div className="signup-box-top top-flashlight light-xl">
              { darkMode ? <Image
                    className="logo-light"
                    src={logoLight}
                    width={476}
                    height={158}
                    alt="LlmWizard Logo"
                  />:<Image
                  className="logo-light"
                  src={boxedLogo}
                  width={476}
                  height={158}
                  alt="LlmWizard Logo"
                />}
            </div>
            <div className="separator-animated animated-true"></div>
            <div className="signup-box-bottom">
              <div className="signup-box-content">
                <h4 className="title">{!toggleAuth ? (<FormattedMessage id="SignIn.title" />):(<FormattedMessage id="SignUp" />)}</h4>
      
                {toggleAuth ? (


                  <form onSubmit={handleSignUpSubmit}>
                                      
                  <div className="input-section mail-section">
                    <div className="icon">
                      <i className="feather-user"></i>
                    </div>
                    <input type="text" placeholder={intl.formatMessage({ id: "SignUp.name" })} value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="input-section mail-section">
                    <div className="icon">
                      <i className="feather-mail"></i>
                    </div>
                    <input type="email" placeholder={intl.formatMessage({ id: "SignUp.email" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  {/* <div className="input-section mail-section">
                    <div className="icon">
                      <i className="feather-phone"></i>
                    </div>
                    <input type="text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </div> */}
                  <div className="input-section password-section">
                    <div className="icon">
                      <i className="feather-lock"></i>
                    </div>
                    <input type="password" placeholder={intl.formatMessage({ id: "SignUp.password" })} value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="input-section password-section">
                    <div className="icon">
                      <i className="feather-lock"></i>
                    </div>
                    <input type="password" placeholder={intl.formatMessage({ id: "SignUp.confirm" })} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  </div>
                  <button style={{justifyContent:'center',alignItems:'center',display:'flex'}} type="submit" className="btn-default">
                   {
                    loading ?
                    ( <LineWave
                      visible={true}
                      height="100"
                      width="100"
                      color="#3D86FE"
                      ariaLabel="line-wave-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                      />)
                    :
                            ( <FormattedMessage id="SignUp" />)
                   }
                  </button>
                  </form>

                ) : (
                  <form onSubmit={handleSignInSubmit}>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input type="email" placeholder={intl.formatMessage({ id: "SignIn.mail" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input type="password" placeholder={intl.formatMessage({ id: "SignIn.password" })}  value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="forget-text">
                      <Link className="btn-read-more" href="#">
                        <span><FormattedMessage id="SignIn.forgot" /></span>
                      </Link>
                    </div>
                    <button style={{justifyContent:'center',alignItems:'center',display:'flex'}} type="submit" className="btn-default" >
                    {
                    loading ?
                    ( <LineWave
                      visible={true}
                      height="100"
                      width="100"
                      color="#3D86FE"
                      ariaLabel="line-wave-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                      />)
                    :
                            ( <FormattedMessage id="SignIn" />)
                   }
                    </button>
                  </form>
                )}
              </div>
              <div className="signup-box-footer">
                <div className="bottom-text">
                    <FormattedMessage id="SignIn.under" />
                  <Link className="btn-read-more ps-2" href={toggleAuth ? "" : "#"} onClick={() => setToggleAuth(!toggleAuth)}>
                    {toggleAuth ? <span ><FormattedMessage id="SignIn" /></span> : <span > <FormattedMessage id="SignUp" /></span>}
                  </Link> 
                </div>
                {toggleAuth && (
                  
                <div style={{ marginTop: "20px" }}>
                  <span>Verification İs Not Received?</span>
                  <button className="btn-read-more" style={{border:'none'}} onClick={resendVerificationEmail}>
                    {loading ? 'Sending...' :   <Link className="btn-read-more" href="#">
                        <span><FormattedMessage id="Resend" /></span>
                      </Link>}
                  </button>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuth;
