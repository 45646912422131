import React from "react";
import Image from "next/image";
import llms from "../../public/images/menu-img/llms.png";
import { FormattedMessage  } from 'react-intl';
import useList from '@/components/isAuth/isAuth';
import { useRouter } from 'next/router';


const Circle = () => {
  const router = useRouter();
  const startFreeTrial = () => {
    if (userID) {
      router.push('/chat'); // Chat sayfasına yönlendirme
    } else {
      router.push('/AuthPage'); // AuthPage sayfasına yönlendirme
    }
  };
  const list = useList();
  const userID = list[0];

  return (
    <>
      <style jsx>{`
        .button-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        
        }
        
        .button-container button {
          width: 100%;
          max-width: 200px;
          text-align: center;
        }

        @media (max-width: 768px) {
          .button-container {
            justify-content: center;
            flex:1;
          }
        }
      `}</style>
    <div className="rainbow-service-area rainbow-section-gap">
      <div className="row" style={{ padding: '0 20px' }}>
        <div className="col-lg-12">
          <div className="void" id="void">
            <div className="crop">
              <ul id="card-list">
                <li className="card-li">
                  <div className="circle-card">
                    <span className="model-name"><FormattedMessage id="circle.c1.title" /></span>
                    <span className="card-li-subtitle"><FormattedMessage id="circle.c1.content" /></span>
                  </div>
                </li>
                <li className="card-li">
                  <div className="circle-card">
                    <span className="model-name"><FormattedMessage id="circle.c2.title" /></span>
                    <span className="card-li-subtitle"><FormattedMessage id="circle.c2.content" /></span>
                  </div>
                </li>
                <li className="card-li">
                  <div className="circle-card">
                    <span className="model-name"><FormattedMessage id="circle.c3.title" /></span>
                    <span className="card-li-subtitle"><FormattedMessage id="circle.c3.content" /></span>
                  </div>
                </li>
                <li className="card-li">
                  <div className="circle-card">
                    <span className="model-name"><FormattedMessage id="circle.c4.title" /></span>
                    <span className="card-li-subtitle"><FormattedMessage id="circle.c4.content" /></span>
                  </div>
                </li>
                <li className="card-li">
                  <div className="circle-card">
                    <span className="model-name"><FormattedMessage id="circle.c5.title" /></span>
                    <span className="card-li-subtitle"><FormattedMessage id="circle.c5.content" /></span>
                  </div>
                </li>
                <li className="card-li">
                  <div className="circle-card">
                    <span className="model-name"><FormattedMessage id="circle.c6.title" /></span>
                    <span className="card-li-subtitle"><FormattedMessage id="circle.c6.content" /></span>
                  </div>
                </li>
              </ul>
              <div className="last-circle"></div>
              <div className="second-circle"></div>
            </div>
            <div className="mask">
              <div style={{ height: '80%' }}>
                <h1 style={{ fontSize: 'clamp(20px, 3vw, 48px)' }}><FormattedMessage id="circle.title" /></h1>
                <p style={{ width: '80%', marginTop: '15px', fontSize: 'clamp(14px, 2vw, 20px)' ,fontFamily:"var(--secondary-font)"}}>
                  <p  style={{ fontSize: 'clamp(14px, 2vw, 20px)' }}>
                  <FormattedMessage id="circle.subtitle1" /></p>
                  <p className="llm-card-subcontent"> <FormattedMessage id="circle.subtitle2" /></p>
                 
                  <span className="llm-card-subcontent"></span>
                </p>
                <div className="button-container">
                <button onClick={startFreeTrial} className="home_top_button">  <FormattedMessage id="circle.start.free" /></button>
                </div>
                <div className="llm-card-image">
                  <h2 style={{ fontSize: 'clamp(15px, 2.5vw, 30px)',paddingRight:'120px',marginTop:22 }}>  <FormattedMessage id="circle.access" /></h2>
                  <Image
                    height={300}
                    width={300}
                  
                    src={llms}
                  />
                </div>
              </div>
            </div>
            <div className="center-circle"></div>
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

export default Circle;
