import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useList from "@/components/isAuth/isAuth";

const DesignBlogsHome = () => {
  const list = useList();
  const userID = list[0];
  const { formatMessage } = useIntl();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="concontainer">
        <div className="grid-container">
          <div
            className={`feature ${scrollPosition > 100 ? "spread" : "stack"} ${
              scrollPosition < 100 ? "hide-right" : ""
            }`}
          >
            <div className="feature-icon dollar">$</div>
            <h3 className="feature-title">
              <FormattedMessage
                id="blogs.feature1.title"
                defaultMessage="Significant Cost Reduction"
              />
            </h3>
            <p>
              <FormattedMessage
                id="blogs.feature1.description"
                defaultMessage="Consolidate your AI expenses with a single subscription, eliminating multiple accounts and invoices."
              />
            </p>
          </div>
          <div
            className={`feature ${scrollPosition > 100 ? "spread" : "stack"} ${
              scrollPosition < 100 ? "hide-right" : ""
            }`}
          >
            <div className="feature-icon shield">
              <i className="feather-shield"></i>
            </div>{" "}
            <h3 className="feature-title">
              <FormattedMessage
                id="blogs.feature2.title"
                defaultMessage="Optimized Model Selection"
              />
            </h3>
            <p>
              <FormattedMessage
                id="blogs.feature2.description"
                defaultMessage="Our platform intelligently chooses the most suitable AI model for your specific tasks and workflows."
              />
            </p>
          </div>
          <div
            className={`feature ${scrollPosition > 100 ? "spread" : "stack"} ${
              scrollPosition < 100 ? "hide-right" : ""
            }`}
          >
            <div className="feature-icon lightning">
              <i className="feather-zap"></i>
            </div>{" "}
            <h3 className="feature-title">
              <FormattedMessage
                id="blogs.feature3.title"
                defaultMessage="Uninterrupted AI Access"
              />
            </h3>
            <p>
              <FormattedMessage
                id="blogs.feature3.description"
                defaultMessage="Maintain continuous workflow with instant model switching, ensuring you're unaffected by outages or downtime."
              />
            </p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .concontainer {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 4rem 2rem;
        }
        .grid-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          max-width: 1200px;
          width: 100%;
        }
        .feature {
          text-align: left;
          padding: 2rem;
          background-color: var(--home-three-box-bg);
          border-radius: 12px;
          border: 1px solid #e5e7eb;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          transition: all 1.5s ease; /* Animasyon süresini 1.5 saniyeye çıkardık */
          position: relative;
        }

        .stack {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          opacity: 1;
        }

        .spread {
          position: relative;
          transform: none;
          opacity: 1;
        }

        .hide-right {
          transform: translateX(200%);
          opacity: 0;
        }

        /* Stack efekti için özel stiller */
        .feature:nth-child(1).stack {
          top: 50%;
          transform: translate(-50%, -52%);
          transition-delay: 0.2s;
          z-index: 3;
        }

        .feature:nth-child(2).stack {
          top: 50%;
          transform: translate(-50%, -50%);
          transition-delay: 0.4s;
          z-index: 2;
        }

        .feature:nth-child(3).stack {
          top: 50%;
          transform: translate(-50%, -48%);
          transition-delay: 0.6s;
          z-index: 1;
        }

        /* Spread efekti için transition */
        .feature.spread {
          transition: all 1.5s ease;
        }

        .grid-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          max-width: 1200px;
          width: 100%;
          position: relative;
          min-height: 300px;
        }

        /* Stack efekti için özel stiller */
        .feature:nth-child(1).stack {
          top: 50%;
          transform: translate(-50%, -52%);
          z-index: 3;
        }

        .feature:nth-child(2).stack {
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }

        .feature:nth-child(3).stack {
          top: 50%;
          transform: translate(-50%, -48%);
          z-index: 1;
        }

        /* Spread efekti için transition */
        .feature.spread {
          transition: all 0.5s ease;
        }

        @media (max-width: 768px) {
          .grid-container {
            grid-template-columns: 1fr;
          }

          .feature.stack {
            width: 90%;
            margin: 0 auto;
          }
        }
        .feature:hover {
          transform: translateY(-4px);
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }
        .feature-icon {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;
          font-size: 20px;
        }
        .dollar {
          background-color: #e8f1ff;
          color: #3b82f6;
        }
        .shield {
          background-color: #e8fae8;
          color: #22c55e;
        }
        .lightning {
          background-color: #f3e8ff;
          color: #8b5cf6;
        }
        .feature-title {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 0.75rem;
          color: var(--home-three-box-title);
        }
        p {
          color: var(--home-three-box-text);
          line-height: 1.5;
        }
        @media (max-width: 768px) {
          .grid-container {
            grid-template-columns: 1fr;
          }
          .feature {
            margin: 0 1rem;
          }
        }
      `}</style>
    </>
  );
};

export default DesignBlogsHome;
