import React, { useEffect } from "react";
import sal from "sal.js";

import PricingData from "../../data/home.json";
import Link from "next/link";
import PricingTwo from "./PricingTwo";
import { FormattedMessage   } from 'react-intl';

const Pricing = () => {
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);
  return (
    <>
      <div className="rainbow-pricing-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="150"
              >
               
                <h2  style={{color:"#386CDC"}}>
                <FormattedMessage id="price.title" /> 
                             </h2>
                <p className="description b1">
               
                <FormattedMessage id="price.desc" />
                </p>
              </div>
            </div>
          </div>
          <div className="row row--15">
          <PricingTwo
                parentClass="col-xl-3 col-lg-6 col-md-6 col-12"
                childClass="tab-content bg-transparent bg-light"
                start={0}
                end={4}
                isHeading={false}
                gap={false}
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
