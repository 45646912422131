import { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { FormattedMessage ,useIntl} from 'react-intl';
import llm from "../../public/images/menu-img/llm-home.svg";
const Welcome = () => {
  const intl=useIntl()
  return (
 
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1 home-welcome"
        data-black-overlay="1"
      >
        <div className="container" style={{display:"flex",justifyContent:"flex-start",alignItems:"center",marginLeft:"10px",height:"100vh"}}>
          <div className="row justify-content-center" style={{width:"65%"}}>
            <div className="col-lg-12">
              <div className="inner text-center mt--60" >
                <h1 className="title display-one titlebg" style={{color:"black"}}>
                  <FormattedMessage id="Welcome.title1" /> <br />
                  <span className="color-off" style={{fontSize:"1.9rem"}}><FormattedMessage id="Welcome.subtitle" /></span>
                </h1>
                <a href="https://llmwizard.com/AuthPage" className="btn-default"  ><FormattedMessage id="start.free.trial" /></a>
               {/*  <p className="b1 desc-text" >
                  <FormattedMessage id="Welcome.subtitle" />
                </p> */}
              
              </div>
             { /* <div>
              <Image
                                 
              src={llm}
              width={500}
              height={500}
              alt="Home Logo"
            />
              </div> */}
            </div>
            
          </div>
        </div>
      {/*   <div className="chatenai-separator has-position-bottom">
          <Image className="w-100" src={separator} alt="" />
        </div> */}
      </div>
    </>
  );
};

export default Welcome;
